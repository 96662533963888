import { template as template_cfa3a6492ffd46f999c6c24a5876b12f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cfa3a6492ffd46f999c6c24a5876b12f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
