import { template as template_de97c4a84af145eaa3483e13ca81c357 } from "@ember/template-compiler";
const FKLabel = template_de97c4a84af145eaa3483e13ca81c357(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
