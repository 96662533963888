import { template as template_6e4da39aba2246a29c8efbf8b2cb1d90 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6e4da39aba2246a29c8efbf8b2cb1d90(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
