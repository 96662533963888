import { template as template_89e231f6085e42c796a77dc71c167c52 } from "@ember/template-compiler";
const SidebarSectionMessage = template_89e231f6085e42c796a77dc71c167c52(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
