import { template as template_00d21e1afa2d47b2b5d67375b5baca50 } from "@ember/template-compiler";
const FKText = template_00d21e1afa2d47b2b5d67375b5baca50(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
